import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProductModel } from '../interface/product.interface';
import { Params } from '../interface/core.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  public skeletonLoader: boolean = false;

  constructor(private http: HttpClient,private route: ActivatedRoute,private router: Router,private AccountService : AccountService) { }

  private wishlistData = new BehaviorSubject<any>(false);
  private cartListData = new BehaviorSubject<any>(false);
  private clearFilter = new BehaviorSubject<any>('');
  private billaddress = new BehaviorSubject<any>('');
  private tempCart = new BehaviorSubject<any>(false);
  private totalcountSource = new BehaviorSubject<boolean>(true);
  totalcount$ = this.totalcountSource.asObservable();
  private cartSource = new BehaviorSubject<any>(null);
  currentCart = this.cartSource.asObservable();
  private pageLoader = new BehaviorSubject<boolean>(false);
  public productQty: number = 1;
qty:number
  // httpOption = {
  //   headers : new HttpHeaders({
  //     'Content-Type' : 'application/json',
  //     // 'Authorization': `bearer ${this.tokenGet}`
      
  //   })
  // }

  getProducts(payload?: Params): Observable<ProductModel> {
    return this.http.get<ProductModel>(`${environment.URL}/product.json`, { params: payload });
  }

  getProductCatagory(id:any){
    return this.http.get(`${environment.apiUrl}Product/contactus/${id}`);
  }

  getCatagoryList(id:any){
    return this.http.get(`${environment.apiUrl}Category/maincategoryscust/${id}`);
}

  getSubCatagoryList(id:any){
    return this.http.get(`${environment.apiUrl}Product/productdessort/${id}`);
  }

  getProductDetail(id:any,skid:any,userid:any){
    if(this.AccountService?.getUserData() && this.AccountService?.getUserData()?.id){
    return this.http.get(`${environment.apiUrl}Product/RetrieveProductsCust/${id}/${skid}?userid=${userid}`);
    }else{
      return this.http.get(`${environment.apiUrl}Product/RetrieveProductsCust/${id}/${skid}`)
    }
  }

  getTrendingProduct(id:any){
    return this.http.get(`${environment.apiUrl}Product/RetrieveTrendingProducts/${id}`);
  }
  
  getFeaturedProduct(id:any){
    return this.http.get(`${environment.apiUrl}Product/RetrieveFeaturedProducts/${id}`);
  }

  getbestProduct(id:any){
    return this.http.get(`${environment.apiUrl}Product/RetrieveBestSellerProducts/${id}`);
  }

  addWishList(data:any){
    return this.http.post(`${environment.apiUrl}Product/addwishlist/`,data);
  }

  getWishList(id:any){
    return this.http.get(`${environment.apiUrl}Product/getwishlist/${id}`);
  }

  deleteWishlistProduct(id:any){
    return this.http.get(`${environment.apiUrl}Product/deletewishlist/${id}`);
  }

  getwishlistFlag(data:any){
    this.wishlistData.next(data)
  }

  setwishlistFlag() {
    return this.wishlistData.asObservable();
  }

  getSubCatagory(sh_id:any,pr_id:any){
    return this.http.get(`${environment.apiUrl}Category/retrievecategory_un_maincat/${sh_id}/${pr_id}`);
  }

  createEnquiry(data:any){
    return this.http.post(`${environment.apiUrl}Product/CreateEnquiry/`,data);
  }

  getContactDetails(id:any){
    return this.http.get(`${environment.apiUrl}Product/contactus/${id}`);
  }

  getSearchList(data:any){
    return this.http.post(`${environment.apiUrl}Product/RetrieveProductsbynameandcat/`,data);
  }

  getTodaysDeals(id:any){
    return this.http.get(`${environment.apiUrl}Product/Todays_Deal/${id}`);
  }

  getAboutUs(id:any){
    return this.http.get(`${environment.apiUrl}Product/aboutus/${id}`);
  }

  getUserOrdered(User_id:any,pr_id:any){
    return this.http.get(`${environment.apiUrl}Order/userOrdered/${User_id}/${pr_id}`);
  }

  getRelatedProducts(sh_id:any,subCat_id:any,pr_id:any){
    return this.http.get(`${environment.apiUrl}Product/listProductsSimilar/${sh_id}/${subCat_id}/${pr_id}`);
  }

  SubCatagoryList(){
    return this.http.get(`${environment.apiUrl}Product/getfullsubcat/`);
  }


  getBannersList(id:any){
    return this.http.get(`${environment.apiUrl}Category/Banners/${id}`);
  }

  newBannersList(id:any){
    return this.http.get(`${environment.apiUrl}Category/new_banners/${id}`);
  }

  deleteCartProduct(id:any){
    return this.http.get(`${environment.apiUrl}Order/deleteOrder/${id}`);
  }

  addToCart(data:any){
    return this.http.post(`${environment.apiUrl}Order/createOrder`,data);
  }

  getProductVariation(id:any,skid:any){
    return this.http.get(`${environment.apiUrl}Product/RetiveSkuComByPid/${id}/${skid}`);
  }

  getCartProductList(userid:any,sh_id:any){
    return this.http.get(`${environment.apiUrl}Order/retrieveOrderbyUseridinMyCart/${userid}/${sh_id}`);
  }

  getCartlistFlag(data:any){
    this.cartListData.next(data)
  }

  setCartlistFlag() {
    return this.cartListData.asObservable();
  }

  deleteProductInCart(id:any){
    return this.http.get(`${environment.apiUrl}Order/deleteOrder/${id}`);
  }

  addBillAddress(id:any,data:any){
    return this.http.post(`${environment.apiUrl}Order/AddBillAddress/${id}`,data);
  }

  getBillAddressList(shid:any,user_id:any){
    return this.http.get(`${environment.apiUrl}Order/AddressList/${shid}/${user_id}`);
  }

  getClear(data:any){
    this.clearFilter.next(data)
  }

  setClear() {
    return this.clearFilter.asObservable();
  }

  getBillAddress(data:any){
    this.billaddress.next(data)
  }

  setBillAddres() {
    return this.billaddress.asObservable();
  }

  getShoDetails(shid:any){
    return this.http.get(`${environment.apiUrl}Product/shopdetails/${shid}`);
  }

  checkoutProduct(data:any){
    return this.http.post(`${environment.apiUrl}Order/checkout`,data);
  }

  saveForLater(data:any){
    return this.http.post(`${environment.apiUrl}Product/savedforlater/`,data);
  }

  placeOrder(data:any,sh_id:any,user_id:any){
    return this.http.put(`${environment.apiUrl}Order/placeOrder/${sh_id}/${user_id}/`,data);
  }

  getOrderDetails(order_id:any){
    return this.http.get(`${environment.apiUrl}Order/retrieveOrderDetailbyOrderId/${order_id}`);
  }

  addRatings(data:any){
    return this.http.post(`${environment.apiUrl}Product/addratings/`,data);
  }

  
  getTempCart(data:any){
    return this.http.post(`${environment.apiUrl}Order/localstorageTempCart/`,data);
  }

  getlocalcart(){
    const products_info: any = localStorage.getItem('add_cart')
    return JSON.parse(products_info)
  }

  getlocalcartProduct(){
    const products_info: any = localStorage.getItem('add_temp_cart')
    return JSON.parse(products_info)
  }

  delUserData(){
    localStorage.removeItem('add_cart');
    localStorage.removeItem('add_temp_cart');
  }

  getTempoCart(data:any){
    this.tempCart.next(data)
  }

  setTempoCart() {
    return this.tempCart.asObservable();
  }

  updateAddress(id:any,data:any){
    return this.http.put(`${environment.apiUrl}Order/UpdateBillAddress/${id}/`,data);
  }

  deleteAddress(id:any){
    return this.http.delete(`${environment.apiUrl}Order/deleteBillAddress/${id}/`);
  }

  getAddressDetail(id:any){
    return this.http.get(`${environment.apiUrl}Order/billAddressDetail/${id}`);
  }

  getDynamicCategory(id:any){
    return this.http.get(`${environment.apiUrl}Product/RetrieveDynamicCategoryProducts/${id}`);
  }

getFeaturedBlog(id:any){
  return this.http.get(`${environment.apiUrl}Category/blogList/${id}`);
}

getBlogDetailes(id:any){
  return this.http.get(`${environment.apiUrl}Category/blogDetail/${id}`);
}
getCompareProductList(shid:any,user_id:any){
return this.http.get(`${environment.apiUrl}Product/compareProductList/${shid}/${user_id}`);
}

addCompareProduct(data:any){
  return this.http.post(`${environment.apiUrl}Product/createCompareProduct/`,data);

}
 RemoveProduct(id:any){
  return this.http.delete(`${environment.apiUrl}Product/compareProductDelete/${id}`)
 }
 notloggedaddproduct(data:any,id:any){
  return this.http.post(`${environment.apiUrl}Product/compareProductLocal/${id}`,data)
 }
 RecentBlog(shid:any){
  return this.http.get(`${environment.apiUrl}Category/blogList/${shid}?recent=true`)
 }

 CategoriesListOfblogs(shid:any){
return this.http.get(`${environment.apiUrl}Category/retreiveCategoryListOfBlog/${shid}`)
 }

 RetreiveCategoryBlogs(id:any){
  return this.http.get(`${environment.apiUrl}Category/retreiveCategoryBlog/${id}`)
 }
 TagListOfBlogs(shid:any){
  return this.http.get(`${environment.apiUrl}Category/retreiveTagListOfBlog/${shid}`)
 }
 RetriveTagBlogs(name:any){
  return this.http.get(`${environment.apiUrl}Category/retreiveBlogWithTag/${name}`)
 }


 updateTotalCount(count: any) {
  this.totalcountSource.next(count);
}


getTotalCount(){
  return this.totalcountSource.asObservable();

}
 changeCart(cart: any) {
  this.cartSource.next(cart);
}

getmessage(id:any){
  return this.http.get(`${environment.apiUrl}Product/flash-message/${id}/`)
}


getpopup(id:any){
return this.http.get(`${environment.apiUrl}Order/latest-order/${id}/`)
}


getPageLoader(value: any) {
  this.pageLoader.next(value);
}


setPageLoader(){
  return this.pageLoader.asObservable();

}

}
