import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TopBarContent } from '../../../../interface/theme-option.interface';
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';

SwiperCore.use([Navigation, Pagination, Autoplay]);

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent  implements OnInit{

  @Input() content: any;
  shopDetails: any;
  constructor( private ProductService:ProductService){}
  ngOnInit(): void {
    this.ProductService.getmessage(environment?.sh_id).subscribe((res:any)=>{
      this.shopDetails = res.data;
      console.log( this.shopDetails,'iiiiilesss')
    })
  }
}
