<div class="product-box" [class]="class">
    <!-- <a href="javascript:void(0)" class="notifi-wishlist" (click)="addToWishlist(product.id,product?.skid) "*ngIf="wishlistIcon">
    <i class="ri-heart-fill" *ngIf="wishlistFlag"></i>
    <i class="ri-heart-line" *ngIf="!wishlistFlag"></i>
    </a> -->
    
    <div class="label-tag sale-tag" *ngIf="product?.stock_status == 'in stock' else out_stock"><span>{{ 'sale' | translate  }}</span></div>
    <ng-template #out_stock>
        <div class="label-tag" *ngIf="product?.stock_status == 'out of stock'"><span>{{ 'Out of stock' | translate  }}</span></div>
    </ng-template>
    <div class="product-image">
        <a [routerLink]="['/product/', product?.productname,product?.id,product?.skid]">
            <img src="{{ product?.product_thumbnails?.original_url ?
                apiUrl+ product?.product_thumbnails?.original_url :
                        'assets/images/product.png' }}" class="img-fluid" [alt]="'product'">
        </a>
        <div class="product-header-top" *ngIf="close">
          <app-button
            [class]="'btn wishlist-button close_button'"
            [spinner]="false"
            [id]="'close_btn'"
            (click)="removeWishlist(product?.prId)">
              <i class="ri-close-line"></i>
          </app-button>
        </div>
        <ul class="product-option" *ngIf="!close">
            <li>
                <a href="javascript:void(0)" (click)="productDetailModal.openModal()">
                    <i class="ri-eye-line"></i>
                </a>
            </li>
            <li>
                <a  (click)="addToCompar(product.id,product.skid)">
                    <i class="ri-refresh-line"></i>
                </a>
            </li>
            <li>
                <a  class="notifi-wishlist" (click)="addToWishlist(product.id,product?.skid)">
                    <i class="{{product?.is_wishlist ? 'ri-heart-fill' : 'ri-heart-line'}} " ></i>
                </a>
            </li>
        </ul>
    </div>
    <div class="product-detail">
        <a [routerLink]="['/product/', product?.productname,product?.id,product?.skid]">
            <h6 class="name">{{ product?.productname }}</h6>
        </a>
        <p>{{ product.short_description }}</p>
        <h6 class="unit mt-1" *ngIf="product?.unit">{{ product?.unit }}</h6>
        <h5 class="sold text-content">
            <span class="theme-color price">{{ product?.salesrate | currencySymbol }}</span>
            <del *ngIf="product?.discount">{{ product.price | currencySymbol }}</del>
        </h5>
        <div class="product-rating">
            <ngb-rating [rate]="product?.rating_count"></ngb-rating>
            <h6 class="theme-color" *ngIf="product?.stock_status">
                {{ product?.stock_status == 'in stock'  ?   'In Stock' : 'Out of Stock' | titleCase }}
            </h6>
        </div>
        <!-- <div class="add-to-cart-box">
            <app-button [id]="'add-to-cart'+product?.id"
                [class]="'btn btn-add-cart addcart-button'"
                [iconClass]="'ri-add-line'"
                [spinner]="false"
                (click)="addToCart(product, 1)"
                *ngIf="product?.stock_status == 'in_stock' else outStock">
                {{ 'add' | translate  }}
                <span class="add-icon">
                    <i class="ri-add-line"></i>
                </span>
            </app-button>
            <ng-template #outStock>
                <app-button [id]="'out-of-stock'+product?.id"
                    [class]="'btn btn-add-cart addcart-button'"
                    [spinner]="false"
                    [disabled]="true">
                    {{ 'sold_out' | translate  }}
                </app-button>
            </ng-template>
            <div class="cart_qty qty-box" [class.open]="cartItem && cartItem.quantity > 0">
                <div class="input-group">
                    <app-button
                        [id]="'qty-left-minus'+product?.id"
                        [spinner]="false"
                        [class]="'qty-left-minus'"
                        (click)="addToCart(product, -1)">
                        <i class="ri-subtract-line"></i>
                    </app-button>
                    <input class="form-control input-number qty-input" type="text" name="quantity"
                        value="{{cartItem && cartItem.quantity ? cartItem.quantity : cartItem}}"
                        readonly>
                    <app-button
                        [id]="'qty-right-plus'+product?.id"
                        [spinner]="false"
                        [class]="'qty-left-plus'"
                        (click)="addToCart(product, 1)">
                        <i class="ri-add-fill"></i>
                    </app-button>
                </div>
            </div>
        </div> -->
    </div>
</div>
<app-product-detail-modal #productDetailModal [product]="product"></app-product-detail-modal>
<app-variation-modal #variationModal></app-variation-modal>
