import { Menu } from "../interface/menu.interface";

export const menu: Menu[] = [
  {
    id: 1,
    title: 'Home',
    type: 'link',
    megaMenu: true,
    megaMenuType: 'image',
    active: false,
    path : ''
   
  },
  {
    id: 2,
    title: 'Collections',
    type: 'link',
    megaMenu: true,
    megaMenuType: 'link',
    path: 'collections',
    active: false,
    slider: 'product',
    children: [
      {
        children: [
          {
            title: 'category',
            type: 'sub',
          },
          {
            title: 'Footwear',
            path: 'collections',
            params: { layout: 'collection_left_sidebar' },
            type: 'link',
            label: 'hot',
            labelClass: 'warning-label',
          },
          {
            title: 'Cosmetics',
            path: 'collections',
            params: { layout: 'collection_right_sidebar' },
            type: 'link',
          },
          {
            title: 'Casuals',
            path: 'collections',
            params: { layout: 'collection_no_sidebar' },
            type: 'link',
          },
          {
            title: 'Party wears',
            path: 'collections',
            params: { layout: 'collection_3_grid' },
            type: 'link',
          },
          {
            title: 'Formals',
            path: 'collections',
            params: { layout: 'collection_4_grid' },
            type: 'link',
            label: 'new',
          },
          // {
          //   title: 'collection_5_grid',
          //   path: 'collections',
          //   type: 'link',
          //   params: { layout: 'collection_5_grid' },
          // },
          // {
          //   title: 'Collection List View',
          //   path: 'collections',
          //   params: { layout: 'collection_list_view' },
          //   type: 'link',
          // },
        ],
      },
      {
        children: [
          {
            title: 'Subcategory',
            type: 'sub',
          },
          {
            title: 'Sandals',
            path: 'collections',
            params: { layout: 'collection_category_slider' },
            type: 'link',
          },
          {
            title: 'Lipstick',
            path: 'collections',
            params: { layout: 'collection_category_sidebar' },
            type: 'link',
            label: 'new',
          },
          {
            title: 'Jeans',
            path: 'collections',
            params: { layout: 'collection_banner' },
            type: 'link',
          },
          {
            title: 'Gown',
            path: 'collections',
            params: { layout: 'collection_offcanvas_filter' },
            type: 'link',
          },
        ],
      },
    ],
  },
  // {
  //   id: 3,
  //   title: '',
  //   type: 'link',
  //   megaMenu: true,
  //   megaMenuType: 'link',
  //   path: 'about-us',
  //   active: false,
    
  //   // slider: 'product',
  // },

  {
    id: 3,
    title: 'blog',
    type: 'link',
    megaMenu: true,
    megaMenuType: 'link',
    path: 'blog/:id',
    active: false,
    slider: 'blog',
    children: [
      {
        children: [
          {
            title: 'blog_pages',
            type: 'sub',
          },
          {
            title: 'blog_list',
            type: 'link',
            path: 'blogs',
            params: { style: 'list_view', sidebar: 'left_sidebar' },
            label: 'new',
          },
         {
            title: 'blog_details',
            path: 'blog/supercharge-your-meals-incorporating-colorful-vegetables-and-fruits',
            type: 'link',
          },
        ],
      },
    ],
  },

  // {
  //   id: 4,
  //   title: 'Contact Us',
  //   type: 'link',
  //   megaMenu: true,
  //   megaMenuType: 'link',
  //   path: 'contact-us',
  //   active: false,
  //   slider: 'product',
  // }

  {
    id: 4,
    title: 'pages',
    megaMenuType: 'sub',
    active: false,
    type: 'link',
    children: [
      {
        title: 'Authentication',
        type: 'link',
        children: [
          {
            title: 'sign_in',
            path: 'auth/login',
            type: 'link',
          },
          {
            title: 'sign_up',
            path: 'auth/register',
            type: 'link',
          },
          {
            title: 'forgot_password',
            path: 'auth/forgot-password',
            type: 'link',
          },
          
        ],
      },
      {
        title: 'account',
        type: 'link',
        children: [
          {
            title: 'my_dashboard',
            path: 'account/dashboard',
            type: 'link',
          },
          {
            title: 'my_notifications',
            path: 'account/notifications',
            type: 'link',
          },
         
          {
            title: 'my_orders',
            path: 'account/order',
            type: 'link',
          },
          {
            title: 'order_details',
            path: 'account/order/details/1000',
            type: 'link',
          },
          
        ],
      },
      {
        title: 'about_us',
        type: 'link',
        path: 'about-us',
      },
      
      {
        title: 'cart',
        // type: 'link',
        path: 'cart',
      },
     
      
      {
        title: 'contact_us',
        path: 'contact-us',
        type: 'link',
      },
     
      {
        title: 'offers',
        type: 'link',
        path: 'offer',
        label: 'new',
      },
      {
        title: 'search',
        type: 'link',
        path: 'search',
      
      },
      {
        title: 'wishlist',
        type: 'link',
        path: 'wishlist',
      },
    
    ],
  },
];
