<div class="footer-contact">
    <ul>
        <li *ngIf="contactDetail?.contact_us">
            <div class="footer-number">
                <i class="ri-phone-line"></i>
                <div class="contact-number">
                    <!-- <h6 class="text-content">{{ 'customer support 24/7' | translate }} :</h6> -->
                    <h5>+91 8767878989</h5>
                    <!-- <h5>{{email}}</h5> -->

                </div>
            </div>
        </li>
        <li *ngIf="contactDetail?.email1">
            <div class="footer-number">
                <i class="ri-mail-line email"></i>
                <div class="contact-number">
                    <!-- <h6 class="text-content">email address :</h6> -->
                    <!-- <h5>{{ contactDetail?.email1 }}</h5> -->
                    <h5 class="email">{{email}}</h5>
                </div>
            </div>
        </li>
        <li class="social-app mb-0" >
            <h5 class="mb-2 text-content">{{ 'download app' | translate }} :</h5>
            <ul>
                <li class="mb-0" >
                    <a href="{{ data?.footer?.play_store_url }}" target="_blank">
                        <img src="assets/images/playstore.svg" alt="playstore">
                    </a>
                </li>
                <li class="mb-0">
                    <a href="{{ data?.footer?.app_store_url }}" target="_blank">
                        <img src="assets/images/appstore.svg" alt="appstore">
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</div>
