import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Menu } from '../../../interface/menu.interface';
import { ProductState } from '../../../../shared/state/product.state';
import { Product, ProductModel } from '../../../../shared/interface/product.interface';
import { BlogState } from '../../../../shared/state/blog.state';
import { Blog, BlogModel } from '../../../../shared/interface/blog.interface';
import * as data from '../../../../shared/data/menu';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Select(ProductState.dealProducts) product$: Observable<Product[]>;
  @Select(BlogState.blog) blog$: Observable<BlogModel>;

  public menu: Menu[] = data.menu;
  public products: Product[];
  public blogs: any[];
  cateogry: any [];
  catagoryArr: any[];
  imgUrl = environment?.imageUrl
  constructor(private router : Router ,private ProductService:ProductService){
    this.product$.subscribe(product => {
      this.products = product.slice(0, 2);
    })

    this.blog$.subscribe(blog =>{
      this.blogs = blog.data.slice(0,2)
    })
  }

//   toggle(menu: Menu){
//     // if(!menu.active){
//     //   this.menu.forEach(item => {
//     //     if(this.menu.includes(menu)){
//     //       item.active = false;
//     //     }
//     //   })
//     // }
//     // menu.active = !menu.active;
//     // console.log('hai toggel',menu?.title)
//     // this.router.navigate(['']);
  
// }

toggle(menu:Menu) {
  
  menu.active = !menu.active;
}

ngOnInit(): void {
  console.log(this.menu,'menu isssss')
  const sh_id = environment?.sh_id;
  this.ProductService.getFeaturedProduct(sh_id).subscribe((res:any)=>{
   this.cateogry=res.slice(0,2);
   

   
    
  })
  
  this.ProductService.getFeaturedProduct(sh_id).subscribe((res:any)=>{
    this.catagoryArr  = res;
    console.log(this.catagoryArr,'arrrayyyyyy')
  });

  this.ProductService.getFeaturedBlog(sh_id).subscribe((res:any)=>{
    this.blogs=res.slice(0,2);
    console.log(this.blogs,'blogsssss')
  });

  if (this.menu.length > 0) {
    this.menu[0].active = true;
  }
}
// onclicks(menu:Menu){
  
// }

onclicks(menu:Menu) {   
 
  
  this.deactivateAllMenus(this.menu);    
  
    menu.active = true;   
  }   
  deactivateAllMenus(menus: any[]) 
  {     menus.forEach(menu => {      
       menu.active = false; 
       console.log('hhhh')       
    if (menu.children && menu.children.length > 0) 
      { this.deactivateAllMenus(menu.children); } }); }
 

}
