import { Component, Input } from '@angular/core';
import { Option } from '../../../../../shared/interface/theme-option.interface';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-footer-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {

  @Input() data: Option | null;
  contactDetail : any;
  constructor(private ProductService : ProductService){
    this.getContactDetails()
  }

  getContactDetails(){
    const sh_id = environment.sh_id;
    this.ProductService.getContactDetails(sh_id).subscribe((res:any)=>{
      this.contactDetail = res;
      console.log(this.contactDetail,'contacttttss')
    })
  }
}
