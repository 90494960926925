
<ul class="navbar-nav">

  <div *ngTemplateOutlet="recursiveMenu; context: { $implicit: menu }"></div>

  <ng-template #recursiveMenu let-menus>

    <ng-container *ngFor="let menu of menus">

      <li

        [ngClass]="[menu.type == 'link' && menu.children ? 'nav-item dropdown' : 'nav-item', menu.megaMenu ? 'dropdown-mega' : '']"

        [class.new-nav-item]="menu.badge"

        [class.active]="menu.active">

        <!-- Your link templates here -->

        <a class="nav-link dropdown-toggle" [routerLink]="menu.path" (click)="onclicks(menu)" [class.show]="menu.active"

          *ngIf="menu.type === 'link'">

          {{ menu.title | translate }}

          <label class="menu-label {{ menu.labelClass }}" *ngIf="menu.label">{{ menu.label | translate }}</label>

        </a>

        <!-- Mega menu templates here -->
        <div class="dropdown-menu dropdown-menu-2" [class.show]="menu.active"
        *ngIf="menu.megaMenu && menu.megaMenuType === 'link'">
        <div class="row">
          <div class="dropdown-column col-xl-3" *ngFor="let megaMenu of menu.children">
            <ng-container *ngFor="let link of megaMenu.children">
              <h5 class="dropdown-header {{ link.class }}" *ngIf="link.type === 'sub'; else links">{{ link.title | translate }}
              </h5>
              <ng-template #links>
                <a class="dropdown-item" [routerLink]="link.path" [queryParams]="link.params"
                  *ngIf="link.type === 'link'">
                  {{ link.title | translate }}
                  <label class="menu-label {{ link.labelClass }}" *ngIf="link.label">{{ link.label | translate }}</label>
                </a>
                <a href="{{link.path}}" class="dropdown-item" *ngIf="link.type === 'external_link'" target="_blank">
                  {{ link.title | translate }}
                  <label class="menu-label {{ link.labelClass }}" *ngIf="link.label">{{ link.label | translate }}</label>
                </a>
              </ng-template>
            </ng-container>
          </div>
          <div class="dropdown-column col-xl-6 d-xl-block d-none" *ngIf="menu.slider === 'product'">
            <div class="menu-product-slider">
              <div class="row">
               
                <div class="col-6" *ngFor="let product of cateogry ">
                  
                  <app-product-box [product]="product" [class]="'product-box-3'" [style]="'horizontal'"></app-product-box>
                </div>
              </div>
            </div>
          </div>
          <div class="dropdown-column col-xl-3 d-xl-block d-none" *ngIf="menu.slider === 'banner_portrait'">
            <div class="menu-img-banner">
              <a routerLink="/product/deliciously-sweet-watermelon" class="text-title">
                <img src="assets/images/mega-menu.png" alt="banner">
              </a>
            </div>
          </div>
          <div class="dropdown-column col-xl-12 d-xl-block d-none" *ngIf="menu.slider === 'banner_landscape'">
            <div class="menu-img-banner rounded overflow-hidden mx-0 mt-3 mb-0">
              <img src="assets/images/mega-menu2.png" class="img-fluid">
            </div>
          </div>
          <div class="dropdown-column col-xl-9 d-xl-block d-none" *ngIf="menu.slider === 'blog'" >
            <div class="row">
              <div class="col-6" *ngFor="let blog of blogs">
                <div class="blog-box sticky-blog">
                  <div class="blog-box-image">
                    <a [routerLink]="['/blog/', blog.id]" class="blog-image">
                      <img
                        [src]="blog.image ? imgUrl+ blog?.image : 'assets/images/product.png'"
                        class="img-fluid" [alt]="blog.title">
                    </a>
                  </div>

                  <a [routerLink]="['/blog/', blog?.slug]" class="blog-detail" tabindex="0">
                    <h6>{{ blog.created_at | date: 'dd MMM yyyy hh:mm:a' }}</h6>
                    <h5>{{ blog.title }}</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <!-- Submenu template -->

        <ul class="dropdown-menu" *ngIf="menu.children && !menu.megaMenu" [class.show]="menu.active">

          <ng-container *ngTemplateOutlet="recursiveMenu; context: { $implicit: menu.children }"></ng-container>

        </ul>

      </li>

    </ng-container>

  </ng-template>

</ul>

 