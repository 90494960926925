<div class="footer-logo-contain">
    <p >Discover convenience redefined at our multipurpose store. From fresh groceries to the latest fashion trends, find everything you need under one roof. Your one-stop shopping destination for a diverse range of products.</p>
    <ul class="address">
        <li *ngIf="contactDetail?.address">
            <i class="ri-home-line"></i>
            <a href="https://www.google.com/maps" target="_blank">{{ contactDetail?.address }}</a>
        </li>
        <li *ngIf="contactDetail?.email1">
            <i class="ri-mail-line"></i>
            <a href="mailto:{{ contactDetail?.email1 }}">{{ contactDetail?.email1 }}</a>
        </li>
    </ul>
</div>
