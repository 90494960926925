<!-- <swiper
  [direction]="'vertical'"
  [loop]="true"
  [autoHeight]="true"
  [allowTouchMove]="false"
  [autoplay]="{ delay: 1000, disableOnInteraction: false }"
  [pagination]="false" [navigation]="false">
  <ng-container *ngFor="let item of shopDetails; let i = index" >
    <ng-container *ngIf="item?.message">
      <ng-template attr.data-swiper-autoplay="{{2000 * (i+1)}}" swiperSlide>
          <div class="d-flex notification-slider justify-content-xxl-center">
            <div class="timer-notification"> -->
              <!-- <span style="color: white;">{{item?.message}}</span>
              <strong>{{item?.highlight}}</strong> -->
              <!-- <h6 [innerHtml]="item.message"></h6>

            </div>
          </div>
      </ng-template>
    </ng-container>
  </ng-container>
</swiper> -->
<swiper
  [pagination]="false"
  [navigation]="false"
  [loop]="false"
  [autoplay]="{ delay: 3000 }"
  class="mySwiper"
>
  <ng-template swiperSlide *ngFor="let message of shopDetails">
    <h6 class="message">{{ message?.message }}&nbsp; &nbsp;<span class="message fw-bold">{{message?.highlight}}</span></h6>
  </ng-template>
</swiper>