<!-- <ng-container *ngIf="image?.redirect_link?.link_type === 'external_url'; else collection">
  <a href=""  class="banner-contain d-block h-100">
    <div class="h-100 bg-size" [ngClass]="class" *ngIf="bgImage" [ngStyle]="{'background-image': 'url(' + image?.image_url + ')'}"></div>
    <img *ngIf="!bgImage" src="{{ image?.image_url }}" class="h-100 bg-img w-100" alt="{{ image?.image_url }}">
  </a>
</ng-container>
<ng-template #collection>
  
</ng-template> -->
<!-- <ng-template #product> -->
  
  <ng-container>
    <!-- <a>
      <div class="h-100 bg-size" [ngClass]="class" *ngIf="bgImage" [ngStyle]="{'background-image': 'url(' + image?.image_url + ')'}"></div>
      <img *ngIf="!bgImage" src="{{imageUrl}}{{ image }}" class="h-100 bg-img w-100" alt="{{ image?.image_url }}">
    </a> -->
    <div class="bg-size sizes" [ngClass]="class" *ngIf="bgImage"  [ngStyle]="{'background-image': 'url('+ imageUrl + image + ')'}"></div>
    <img  src="{{imageUrl}}{{ image }}" *ngIf="!bgImage" class="h-100 bg-img w-100" alt="{{ image }}" >
  </ng-container>
 <!-- </ng-template>  -->
 <ng-template #onlyImage>
    <div class="h-100 bg-size" [ngClass]="class"  [ngStyle]="{'background-image': 'url(' + image?.image_url + ')'}"></div>
    <img *ngIf="!bgImage" src="{{imageUrl}}{{ image?.image_url }}" class="h-100 bg-img w-100" alt="{{ image?.image_url }}">
</ng-template> 
