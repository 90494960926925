import { Component, Input } from '@angular/core';
import { Option } from '../../../../../shared/interface/theme-option.interface';
import { Footer } from '../../../../../shared/interface/theme.interface';
import { environment } from 'src/environments/environment';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-footer-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class FooterLogoComponent {
  imgUrl = environment?.imageUrl
  @Input() data: Option;
  @Input() footer: Footer;
  shopDetails: any;

  constructor(private ProductService :ProductService){}

  ngOnInit(): void {
    this.ProductService.getShoDetails(environment?.sh_id).subscribe((res:any)=>{
      this.shopDetails = res;
    })
  }
}
